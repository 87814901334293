import { QueryClient } from '@tanstack/react-query'
import { persistQueryClient } from '@tanstack/react-query-persist-client'
import { createSyncStoragePersister } from '@tanstack/query-sync-storage-persister'


export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: 0,
      // staleTime: 0,
      // gcTime: 0,
      staleTime: 5 * 60 * 1000,
      gcTime: 10 * 60 * 1000,
      refetchOnWindowFocus: false,
    },
  },
})

const persister = createSyncStoragePersister({
  storage: window.localStorage,
})

persistQueryClient({
  queryClient,
  persister,
  maxAge: 5 * 60 * 1000,
  
  // 로그인 시에는 최신 정보 가져오기
  // dehydrateOptions: {
  //   shouldDehydrateQuery: (query) => {
  //     return query.queryKey[0] !== 'auth';
  //   },
  // },
})