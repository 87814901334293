
import { DEFAULT_ANIMATION, EDITABLE_HOVER_CLASSES } from "@/shared/constants/styles";
import { useEditableComponent } from "@/shared/hooks/useEditableComponent";
import { ExtendedAnimationConfig } from "../types/animation";
import { useAnimation } from "../hooks/animation/useAnimation";
import { isBuilderMode } from '@/shared/constants/environment';
import DOMPurify from "dompurify";


interface TextWrapperProps {
  content: string;
  className?: string;
  editable?: {
    type: 'plainText' | 'richText';
    path: string;
  };
  animation?: ExtendedAnimationConfig | 'none';
}
 
const TextWrapper = ({ 
  content, 
  className = '', 
  editable,
  animation = DEFAULT_ANIMATION
}: TextWrapperProps) => {
  const { handleComponentSelect } = useEditableComponent();
  
  // animation이 'none'이면 undefined로 설정, 아니면 병합된 설정 사용
  const finalAnimation = animation === 'none' 
    ? undefined 
    : typeof animation === 'object' 
      ? { ...DEFAULT_ANIMATION, ...animation }
      : DEFAULT_ANIMATION;

  const [ref, animationStyles] = useAnimation(finalAnimation);

  const handleClick = () => {
    if (!editable) return;

    handleComponentSelect({
      type: editable.type,
      path: editable.path,
      content: content,
      styles: {}
    });
  };
 
  return (
    <div
      ref={ref}
      className={`
        relative
        ${className}
        ${isBuilderMode() && editable ? EDITABLE_HOVER_CLASSES : ''}
      `}
      style={animationStyles}
      onClick={handleClick}
      dangerouslySetInnerHTML={{ 
        __html: DOMPurify.sanitize(content) 
      }}
    />
  );
};

export default TextWrapper;