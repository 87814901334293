import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuthStore } from '@/builder/stores/authStore';
import { api } from '@/builder/utils/api';
import { useTemplateStore } from '@/shared/stores/templateStore';
import toast from 'react-hot-toast';

const GoogleCallback = () => {
  const navigate = useNavigate();


  
  useEffect(() => {
    const handleCallback = async () => {
      try {
        const code = new URL(window.location.href).searchParams.get('code');
        const redirectUri = `${window.location.origin}/auth/google`;
        
        if (code) {
          const response = await api.post('/api/auth/google', {
            code,
            redirectUri
          });
          
          if (response.data.success) {
            useAuthStore.setState({
              token: response.data.data.token,
              user: {
                userId: response.data.data.user.userId,
                email: response.data.data.user.email,
                role: response.data.data.user.role,
                ceoName: response.data.data.user.ceoName,
              },
              isAuthenticated: true
            });

            localStorage.setItem('token', response.data.data.token);

            useTemplateStore.getState().reset();
            if (!response.data.data.user.isCompleted) {
              toast.success('회원가입을 완료했습니다. 이제 추가정보를 입력해주세요.');
              navigate('/auth/complete-profile');
            } else {
              toast.success('구글 로그인이 완료되었습니다');
              navigate('/admin');
            }
          }
        }
      } catch (error: any) {
        console.error('구글 로그인 에러:', error);
        
        // 에러 응답이 있는 경우
        if (error.response?.data) {
          if (error.response.data.code === 'EMAIL_ALREADY_EXISTS') {
            toast.error('이미 다른 방법으로 가입된 이메일입니다. 다른 방법으로 로그인해주세요.');
          } else {
            toast.error(error.response.data.error || '구글 로그인에 실패했습니다');
          }
        } else {
          toast.error('구글 로그인에 실패했습니다');
        }
        
        navigate('/auth/login');
      }
    };
    
    handleCallback();
  }, [navigate]);
  
  return (
    <div className="bg-black flex justify-center items-center h-screen">
      <div className="animate-spin rounded-full h-12 w-12 border-t-2 border-b-2 border-red-500"></div>
    </div>
  );
};

export default GoogleCallback;