import { Quill } from 'react-quill';
import toast from 'react-hot-toast';
import ImageResize from '@taoqf/quill-image-resize-module';

const Parchment = Quill.import('parchment');
const Size = Quill.import('attributors/style/size');

// 사이즈 맵 설정
export const SIZE_MAP: any = {
  'xs': '0.75rem',
  // 'xs': 'clamp(0.625rem, 2vw, 0.75rem)',
  'sm': '0.875rem',
  // 'sm': 'clamp(0.75rem, 2.5vw, 0.875rem)',
  'base': '1rem',
  // 'base': 'clamp(0.875rem, 3vw, 1rem)',
  'lg': 'clamp(1.05rem, 3.5vw, 1.125rem)',
  'xl': 'clamp(1.125rem, 4vw, 1.25rem)',
  '2xl': 'clamp(1.25rem, 4.5vw, 1.5rem)',
  '3xl': 'clamp(1.5rem, 5vw, 1.875rem)',
  '4xl': 'clamp(1.875rem, 5.5vw, 2.25rem)',
  '5xl': 'clamp(2.25rem, 6vw, 3rem)',
  '6xl': 'clamp(2.75rem, 6.5vw, 3.5rem)'
};

// 커스텀 Clipboard Module 정의
class CustomClipboard {
  quill: any;
  options: any;

  constructor(quill: any, options: any) {
    this.quill = quill;
    this.options = options;
    this.quill.root.addEventListener('paste', this.handlePaste.bind(this));
  }

  handlePaste(e: ClipboardEvent) {
    const clipboardData = e.clipboardData;
    const items = clipboardData?.items;
    
    if (items) {
      for (let i = 0; i < items.length; i++) {
        if (items[i].type.indexOf('image') !== -1) {
          e.preventDefault();
          toast.error('이미지는 상단 이미지 버튼을 통해 업로드해주세요.');
          return;
        }
      }
    }

    const html = clipboardData?.getData('text/html');
    if (html && html.includes('data:image')) {
      e.preventDefault();
      toast.error('이미지는 상단 이미지 버튼을 통해 업로드해주세요.');
    }
  }
}

// 커스텀 이미지 모듈 정의
class ImageUploader {
  quill: any;
  options: any;
  openImagePicker: () => void;

  constructor(quill: any, options: any) {
    this.quill = quill;
    this.options = options;
    this.openImagePicker = options.openImagePicker;
    
    const toolbar = quill.getModule('toolbar');
    toolbar.addHandler('image', this.selectImage.bind(this));
  }

  selectImage() {
    this.openImagePicker();
  }
}

// 모든 Quill 모듈 등록
export const registerQuillModules = () => {
  // Register the image resize module
  Quill.register('modules/imageResize', ImageResize);
  
  Quill.register('modules/customClipboard', CustomClipboard);
  Quill.register('modules/imageUploader', ImageUploader);
  Quill.register(Size, true);

  // 사이즈 관련 설정
  const CustomSize = new Parchment.Attributor.Style('size', 'font-size', {
    scope: Parchment.Scope.INLINE,
    // whitelist: Object.values(SIZE_MAP)
  });
  Quill.register(CustomSize, true);

  // 라인 높이 설정
  const LineHeight = new Parchment.Attributor.Style('lineHeight', 'line-height', {
    scope: Parchment.Scope.BLOCK,
    // whitelist: ['1', '1.2', '1.5', '1.8', '2.0']
  });
  Quill.register(LineHeight, true);

  // 폰트 두께 설정
  const FontWeight = new Parchment.Attributor.Style('weight', 'font-weight', {
    scope: Parchment.Scope.INLINE,
    // whitelist: ['300', '400', '500', '600', '700', '800']
  });
  Quill.register(FontWeight, true);

  // 자간 설정
  const LetterSpacing = new Parchment.Attributor.Style('letterSpacing', 'letter-spacing', {
    scope: Parchment.Scope.INLINE,
    // whitelist: ['-1em', '-0.05em', '-0.025em', '0', '0.025em', '0.05em', '0.1em']
  });
  Quill.register(LetterSpacing, true);

  // 텍스트 정렬 설정
  const TextAlign = new Parchment.Attributor.Style('textAlign', 'text-align', {
    scope: Parchment.Scope.BLOCK,
    // whitelist: ['left', 'center', 'right']
  });
  Quill.register(TextAlign, true);
};

// 사이즈 핸들러
export const sizeHandler = function(this: any, value: string) {
  if (value === '크기') return;
  const quill = this.quill;
  const range = quill.getSelection();
  if (range) {
    quill.format('size', SIZE_MAP[value], 'user');
  }
};

// import { Quill } from 'react-quill';
// import toast from 'react-hot-toast';

// const Parchment = Quill.import('parchment');
// const Size = Quill.import('attributors/style/size');

// // 사이즈 맵 설정
// export const SIZE_MAP: any = {
//   'xs': '0.75rem',
//   // 'xs': 'clamp(0.625rem, 2vw, 0.75rem)',
//   'sm': '0.875rem',
//   // 'sm': 'clamp(0.75rem, 2.5vw, 0.875rem)',
//   'base': '1rem',
//   // 'base': 'clamp(0.875rem, 3vw, 1rem)',
//   'lg': 'clamp(1.05rem, 3.5vw, 1.125rem)',
//   'xl': 'clamp(1.125rem, 4vw, 1.25rem)',
//   '2xl': 'clamp(1.25rem, 4.5vw, 1.5rem)',
//   '3xl': 'clamp(1.5rem, 5vw, 1.875rem)',
//   '4xl': 'clamp(1.875rem, 5.5vw, 2.25rem)',
//   '5xl': 'clamp(2.25rem, 6vw, 3rem)',
//   '6xl': 'clamp(2.75rem, 6.5vw, 3.5rem)'
// };

// // 커스텀 Clipboard Module 정의
// class CustomClipboard {
//   quill: any;
//   options: any;

//   constructor(quill: any, options: any) {
//     this.quill = quill;
//     this.options = options;
//     this.quill.root.addEventListener('paste', this.handlePaste.bind(this));
//   }

//   handlePaste(e: ClipboardEvent) {
//     const clipboardData = e.clipboardData;
//     const items = clipboardData?.items;
    
//     if (items) {
//       for (let i = 0; i < items.length; i++) {
//         if (items[i].type.indexOf('image') !== -1) {
//           e.preventDefault();
//           toast.error('이미지는 상단 이미지 버튼을 통해 업로드해주세요.');
//           return;
//         }
//       }
//     }

//     const html = clipboardData?.getData('text/html');
//     if (html && html.includes('data:image')) {
//       e.preventDefault();
//       toast.error('이미지는 상단 이미지 버튼을 통해 업로드해주세요.');
//     }
//   }
// }

// // 커스텀 이미지 모듈 정의
// class ImageUploader {
//   quill: any;
//   options: any;
//   openImagePicker: () => void;

//   constructor(quill: any, options: any) {
//     this.quill = quill;
//     this.options = options;
//     this.openImagePicker = options.openImagePicker;
    
//     const toolbar = quill.getModule('toolbar');
//     toolbar.addHandler('image', this.selectImage.bind(this));
//   }

//   selectImage() {
//     this.openImagePicker();
//   }
// }

// // 모든 Quill 모듈 등록
// export const registerQuillModules = () => {
//   Quill.register('modules/customClipboard', CustomClipboard);
//   Quill.register('modules/imageUploader', ImageUploader);
//   Quill.register(Size, true);

//   // 사이즈 관련 설정
//   const CustomSize = new Parchment.Attributor.Style('size', 'font-size', {
//     scope: Parchment.Scope.INLINE,
//     // whitelist: Object.values(SIZE_MAP)
//   });
//   Quill.register(CustomSize, true);

//   // 라인 높이 설정
//   const LineHeight = new Parchment.Attributor.Style('lineHeight', 'line-height', {
//     scope: Parchment.Scope.BLOCK,
//     // whitelist: ['1', '1.2', '1.5', '1.8', '2.0']
//   });
//   Quill.register(LineHeight, true);

//   // 폰트 두께 설정
//   const FontWeight = new Parchment.Attributor.Style('weight', 'font-weight', {
//     scope: Parchment.Scope.INLINE,
//     // whitelist: ['300', '400', '500', '600', '700', '800']
//   });
//   Quill.register(FontWeight, true);

//   // 자간 설정
//   const LetterSpacing = new Parchment.Attributor.Style('letterSpacing', 'letter-spacing', {
//     scope: Parchment.Scope.INLINE,
//     // whitelist: ['-1em', '-0.05em', '-0.025em', '0', '0.025em', '0.05em', '0.1em']
//   });
//   Quill.register(LetterSpacing, true);

//   // 텍스트 정렬 설정
//   const TextAlign = new Parchment.Attributor.Style('textAlign', 'text-align', {
//     scope: Parchment.Scope.BLOCK,
//     // whitelist: ['left', 'center', 'right']
//   });
//   Quill.register(TextAlign, true);
// };

// // 사이즈 핸들러
// export const sizeHandler = function(this: any, value: string) {
//   if (value === '크기') return;
//   const quill = this.quill;
//   const range = quill.getSelection();
//   if (range) {
//     quill.format('size', SIZE_MAP[value], 'user');
//   }
// };