import { useAuthStore } from '@/builder/stores/authStore';
import { api } from '@/builder/utils/api';
import { useTemplateStore } from '@/shared/stores/templateStore';
import { useEffect } from 'react';
import toast from 'react-hot-toast';
import { useNavigate } from 'react-router-dom';

const NaverCallback = () => {
  const navigate = useNavigate();
 
  useEffect(() => {
    const handleCallback = async () => {
      try {
        const code = new URL(window.location.href).searchParams.get('code');
        const state = new URL(window.location.href).searchParams.get('state');
        
        if (code && state) {
          const response = await api.post('/api/auth/naver', {
            code,
            state
          });
 
          if (response.data.success) {
            useAuthStore.setState({ 
              token: response.data.data.token,
              user: {
                userId: response.data.data.user.userId,
                email: response.data.data.user.email,
                role: response.data.data.user.role,
                ceoName: response.data.data.user.name
              },
              isAuthenticated: true
            });
            useTemplateStore.getState().reset();
            toast.success('네이버 로그인이 완료되었습니다');
            navigate('/admin');
          }
        }
      } catch (error) {
        console.error('네이버 로그인 에러:', error);
        toast.error('네이버 로그인에 실패했습니다');
        navigate('/auth/login');
      }
    };
 
    handleCallback();
  }, [navigate]);
 
  return (
    <div className="bg-black flex justify-center items-center h-screen">
      <div className="animate-spin rounded-full h-12 w-12 border-t-2 border-b-2 border-red-500"></div>
    </div>
  );
 };
 
 export default NaverCallback;