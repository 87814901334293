// src/pages/admin/posts/EditPost.tsx
import { useNavigate, useParams } from 'react-router-dom';
import { useTemplateStore } from '@/shared/stores/templateStore';
import Header from '@/builder/components/admin/Header';
import PostForm from '@/builder/components/posts/PostForm';
import { useEffect, useMemo } from 'react';
import { usePosts, useUpdatePost } from '@/builder/queries/postQueries';

const EditPost = () => {
  const navigate = useNavigate();
  const { postId } = useParams();
  const { selectedTemplate } = useTemplateStore();
  const selectedTemplateId = selectedTemplate?.templateId;
  // const selectedTemplateId = useTemplateStore(state => state.selectedTemplateId);
  const { data } = usePosts(); // 현재 캐시된 posts 데이터 사용
  const updatePost = useUpdatePost(selectedTemplateId as string);

  // 현재 편집할 post 찾기
  const post = useMemo(() => {
    if (!data?.posts || !postId) return null;
    return data.posts.find((p: any) => p.id.toString() === postId);
  }, [data?.posts, postId]);

  useEffect(() => {
    if (!selectedTemplateId) {
      alert('템플릿이 선택되지 않았습니다.');
      navigate('/admin/posts');
    }
  }, [selectedTemplateId, navigate]);

  useEffect(() => {
    // post가 없으면 목록으로 리다이렉트
    if (data?.posts && !post) {
      alert('존재하지 않는 게시물입니다.');
      navigate('/admin/posts');
    }
  }, [post, data?.posts, navigate]);

  if (!post) {
    return (
      <div className="flex items-center justify-center h-64">
        <p className="text-gray-500">게시물을 찾을 수 없습니다.</p>
      </div>
    );
  }

  const handleSubmit = async (formData: {
    title: string;
    subtitle: string;
    content: string;
    thumbnailUrl: string;
    externalUrl?: string;
  }) => {
    if (!selectedTemplateId || !postId) {
      alert('템플릿이나 게시물 정보가 올바르지 않습니다.');
      return;
    }

    if (!formData.title || !formData.content) {
      alert('제목과 내용은 필수 입력사항입니다.');
      return;
    }

    try {
      await updatePost.mutateAsync({
        id: parseInt(postId),
        ...formData
      });
      
      navigate('/admin/posts');
    } catch (error) {
      console.error('Error updating post:', error);
    }
  };

  const handleCancel = () => {
    navigate('/admin/posts');
  };

  return (
    <div className="space-y-6 max-w-5xl mx-auto">
      <Header
        title="게시물 수정"
        subtitle="기존 컨텐츠를 수정합니다"
      />

      <div className="bg-white rounded-xl shadow-lg p-6">
        <PostForm
          initialData={{
            title: post.title,
            subtitle: post.subtitle || '',
            content: post.content,
            thumbnailUrl: post.thumbnailUrl || '',
            externalUrl: post.externalUrl || '',
          }}
          isSubmitting={updatePost.isPending}
          onSubmit={handleSubmit}
          onCancel={handleCancel}
        />
      </div>
    </div>
  );
};

export default EditPost;