import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { paymentApi } from '../utils/api';
import { templateKeys } from './templateQueries';
import { useUser } from '../stores/authStore';
import { useNavigate } from 'react-router-dom';
import { handleQueryError } from '../utils/queryErrorHandler';
import toast from 'react-hot-toast';

interface BillingResponse {
  success: boolean;
  data?: any;
  error?: string;
}

interface PaymentInfo {
  customerId: string;
  subscriptions: Array<{
    templateId: string;
    status: string;
    plan: string;
    billingCycle: number;
    nextBillingDate: string;
    expiryDate: string;
    autoRenewal: boolean;
    startDate: string;
    paymentType: string;
    billingHistory: Array<{
      orderId: string;
      amount: number;
      paymentDate: string;
      status: string;
    }>;
  }>;
  card: {
    cardCompany: string;
    cardType: string;
    ownerType: string;
    cardNumber: string;
  };
  createdAt: string;
  updatedAt: string;
}

export const paymentKeys = {
  all: (userId: string) => ['payment', userId] as const,
  details: (userId: string) => ['payment', userId, 'details'] as const
};

export const usePaymentInfo = () => {
  const user = useUser();
  const navigate = useNavigate();

  return useQuery<PaymentInfo>({
    queryKey: paymentKeys.details(user?.userId || ''),
    queryFn: async () => {
      try {
        const { data } = await paymentApi.get<BillingResponse>('/api/billing/payment');
        return data.data;
      } catch (error) {
        handleQueryError(error, navigate);
        throw error;
      }
    },
    enabled: !!user?.userId,  // 유저id가 있을 때만 쿼리 실행
  });
};

export const useTrial = () => {
  const queryClient = useQueryClient();
  const user = useUser();  // 현재 사용자 정보 가져오기

  return useMutation({
    mutationFn: async ({ 
      authKey, 
      customerKey,
      paymentData
    }: { 
      authKey: string;
      customerKey: string;
      paymentData: any;
    }) => {
      const { data } = await paymentApi.post<BillingResponse>('/api/billing/trial', {
        authKey,
        customerKey,
        paymentData
      });
      return data;
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: paymentKeys.details(user?.userId || '') });
      queryClient.invalidateQueries({ queryKey: templateKeys.list(user?.userId || '') });
    },
    onError: (error) => {
      console.error('Billing key issuance error:', error);
    }
  });
};


export const useIssueBilling = () => {
  const queryClient = useQueryClient();
  const user = useUser();  // 현재 사용자 정보 가져오기

  return useMutation({
    mutationFn: async ({ 
      authKey, 
      customerKey,
      paymentData
    }: { 
      authKey: string;
      customerKey: string;
      paymentData: any;
    }) => {
      const { data } = await paymentApi.post<BillingResponse>('/api/billing/issue', {
        authKey,
        customerKey,
        paymentData
      });
      return data;
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: paymentKeys.details(user?.userId || '') });
      queryClient.invalidateQueries({ queryKey: templateKeys.list(user?.userId || '') });
    },
    onError: (error) => {
      console.error('Billing key issuance error:', error);
    }
  });
};


export const useConvertToSubscription = () => {
  const queryClient = useQueryClient();
  const user = useUser();

  return useMutation({
    mutationFn: async ({
      authKey,
      customerKey,
      paymentData
    }: {
      authKey: string;
      customerKey: string;
      paymentData: any
    }) => {
      const { data } = await paymentApi.post<BillingResponse>('/api/billing/convert-subscription', {
        authKey,
        customerKey,
        paymentData
      });
      return data;
    },
    onSuccess: () => {
      // 결제 정보와 템플릿 정보를 모두 갱신
      queryClient.invalidateQueries({ queryKey: paymentKeys.details(user?.userId || '') });
      queryClient.invalidateQueries({ queryKey: templateKeys.list(user?.userId || '') });
    },
    onError: (error) => {
      console.error('Convert to subscription error:', error);
    }
  });
};

export const useUpdateCard = () => {
  const queryClient = useQueryClient();
  const user = useUser();

  return useMutation({
    mutationFn: async ({
      authKey,
      customerKey
    }: {
      authKey: string;
      customerKey: string;
    }) => {
      const { data } = await paymentApi.post<BillingResponse>('/api/billing/update-card', {
        authKey,
        customerKey
      });
      return data;
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: paymentKeys.details(user?.userId || '') });
    },
    onError: (error) => {
      console.error('Update card error:', error);
    }
  });
};


export const useRefund = () => {
  const queryClient = useQueryClient();
  const user = useUser();

  return useMutation({
    mutationFn: async ({
      templateId,
      cancelType
    }: {
      templateId: string;
      cancelType: 'trial' | 'conversion' | 'trialWithoutPayment';
    }) => {
      const toastId = toast.loading('환불 요청중입니다');
      try {
        const { data } = await paymentApi.post<BillingResponse>('/api/billing/refund', {
          templateId,
          cancelType
        });
        toast.success(
          cancelType === 'trialWithoutPayment'
          ? '체험판이 종료되었습니다.'
          : '환불 요청이 완료되었습니다. 영업일 기준 3~4일이 소요됩니다.'
          , { id: toastId }
        );
        return data;
      } catch (error) {
        toast.error('환불 요청이 실패했습니다. 다시 시도해주세요.', { id: toastId });
        throw error;
      }
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: paymentKeys.details(user?.userId || '') });
      queryClient.invalidateQueries({ queryKey: templateKeys.list(user?.userId || '') });
    },
    onError: (error) => {
      console.error('Refund error:', error);
    }
  });
};

// 구독 갱신 설정 mutation (autoRenewal 토글)
export const useSubscriptionRenewal = () => {
  const queryClient = useQueryClient();
  const user = useUser();

  return useMutation({
    mutationFn: async ({
      templateId,
      action
    }: {
      templateId: string;
      action: 'enable' | 'disable';
    }) => {
      const toastId = toast.loading('처리중입니다');
      try {
        const { data } = await paymentApi.post<BillingResponse>('/api/billing/subscription/renewal', {
          templateId,
          action
        });
        toast.success(
          action === 'enable' ? '구독 갱신이 설정되었습니다.' : '구독 갱신이 해제되었습니다.', 
          { id: toastId }
        );
        return data;
      } catch (error) {
        toast.error('처리에 실패했습니다. 다시 시도해주세요.', { id: toastId });
        throw error;
      }
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: paymentKeys.details(user?.userId || '') });
      queryClient.invalidateQueries({ queryKey: templateKeys.list(user?.userId || '') });
    },
    onError: (error) => {
      console.error('Subscription renewal setting error:', error);
    }
  });
};

// 비활성 구독 재결제 mutation
export const useReactivateSubscription = () => {
  const queryClient = useQueryClient();
  const user = useUser();

  return useMutation({
    mutationFn: async ({
      paymentData
    }: {
      paymentData: any
    }) => {
      const toastId = toast.loading('결제를 진행중입니다');
      try {
        const { data } = await paymentApi.post<BillingResponse>('/api/billing/subscription/reactivate', {
          paymentData
        });
        toast.success('결제가 완료되었습니다.', { id: toastId });
        return data;
      } catch (error) {
        toast.error('결제에 실패했습니다. 다시 시도해주세요.', { id: toastId });
        throw error;
      }
    },
    onSuccess: () => {
      // 결제 정보와 템플릿 정보를 모두 갱신
      queryClient.invalidateQueries({ queryKey: paymentKeys.details(user?.userId || '') });
      queryClient.invalidateQueries({ queryKey: templateKeys.list(user?.userId || '') });
    },
    onError: (error) => {
      console.error('Reactivate subscription error:', error);
    }
  });
};