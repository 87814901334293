 // src/queries/templateQueries.ts
import { TemplateResponse } from '@/builder/types/template';
import { useQuery, useMutation, useQueryClient, useQueries, UseQueryOptions } from '@tanstack/react-query';
import toast from 'react-hot-toast';
import { api, withAuthInterceptor } from '../utils/api';
import { useNavigate } from 'react-router-dom';
import { AxiosError } from 'axios';
import { useUser } from '../stores/authStore';
import { handleQueryError } from '../utils/queryErrorHandler';


export const templateKeys = {
  all: (userId: string) => ['templates', userId] as const,
  list: (userId: string) => ['templates', userId, 'list'] as const,
  data: (userId: string, id: string) => ['templates', userId, id, 'data'] as const,
}

export const useTemplates = () => {
  const user = useUser();
  const navigate = useNavigate();

  return useQuery({
    queryKey: templateKeys.list(user?.userId || ''),
    queryFn: async () => {
      try {
        const { data } = await api.get<TemplateResponse>('/api/templates');
        return data.data.templates;
      } catch (error) {
        handleQueryError(error, navigate);
        throw error;
      }
    },
    enabled: !!user?.userId,  // 유저id가 있을 때만 쿼리 실행
  });
};



export const useTemplateData = (templateId: string) => {
  // const api = withAuthInterceptor();
  const user = useUser();  // 현재 사용자 정보 가져오기
  const navigate = useNavigate();

  const { data: queryData, isLoading, error } = useQuery({
    queryKey: templateKeys.data(user?.userId || '', templateId),
    // queryKey: templateKeys.data(templateId),
    queryFn: async () => {
      try {
        const { data } = await api.get(`/api/templates/${templateId}/data`);
        return data.data;
      } catch (error) {
        handleQueryError(error, navigate);
        throw error;
      }
    },
    enabled: !!user?.userId  // 유저id가 있을 때만 쿼리 실행
  });

  return {
    data: queryData,
    isLoading,
    error
  };
};


export const useCreateTemplate = () => {
  // const api = withAuthInterceptor();
  const queryClient = useQueryClient();
  const user = useUser();  // 현재 사용자 정보 가져오기
  const navigate = useNavigate();
  
  return useMutation({
    mutationFn: async ({
      name,
      type
    }: { 
      name: string; 
      type: string; 
    }) => {
      const { data } = await api.post('/api/templates', { name, type });
      return data;
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: templateKeys.list(user?.userId || '') });
    },
    onError: (error) => handleQueryError(error, navigate)
  });
};


// 템플릿 데이터 수정
export const useUpdateTemplate = (templateId: string) => {
  // const api = withAuthInterceptor();
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const user = useUser();  // 현재 사용자 정보 가져오기

  return useMutation({
    mutationFn: async ({ type, data }: { 
      type: 'data'; 
      // type: 'essential' | 'pages'; 
      data: any; 
    }) => {
      const { data: responseData } = await api.put(`/api/templates/${templateId}/${type}`, data);
      return responseData;
    },
    onSuccess: (_, variables) => {
      queryClient.invalidateQueries({ queryKey: templateKeys.data(user?.userId || '', templateId) });
    },
    onError: (error) => handleQueryError(error, navigate)
  });
};