import axios, { AxiosInstance, InternalAxiosRequestConfig } from 'axios';

const API_BASE = 'https://web-builder-api.quokkawoo.workers.dev';
const PAYMENT_API_BASE = 'https://payment-workers.quokkawoo.workers.dev';

// 기본 API 클라이언트
const api = axios.create({
  baseURL: API_BASE,
});

// 결제 전용 API 클라이언트
const paymentApi = axios.create({
  baseURL: PAYMENT_API_BASE,
});

// 각 인스턴스별로 인터셉터 추가 여부를 추적하는 Map
const interceptorFlags = new Map<AxiosInstance, boolean>();

/**
 * 인터셉터가 추가된 API 클라이언트를 반환
 * @param client AxiosInstance
 * @returns AxiosInstance
 */
export const withAuthInterceptor = (client: AxiosInstance): AxiosInstance => {
  if (!interceptorFlags.get(client)) {
    client.interceptors.request.use(
      (config: InternalAxiosRequestConfig) => {
        const token = localStorage.getItem('token');
        if (token) {
          config.headers.set('Authorization', `Bearer ${token}`);
        }
        return config;
      },
      (error) => {
        return Promise.reject(error);
      }
    );
    interceptorFlags.set(client, true);
  }
  return client;
};

// 각 API 클라이언트에 인터셉터 적용
withAuthInterceptor(api);
withAuthInterceptor(paymentApi);

export { api, paymentApi };
