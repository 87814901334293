// src/pages/admin/UserInfo.tsx
import Header from '@/builder/components/admin/Header';
import PasswordChangeForm from '@/builder/components/auth/PasswordChangeForm';
import { useUser } from '@/builder/stores/authStore';

const UserInfo = () => {
  const user = useUser();
  console.log(user);
  

  return (
    <div className="space-y-8 max-w-4xl mx-auto">
      <Header
        title="회원정보"
        subtitle="디스이즈웹 회원정보를 관리할 수 있습니다"
      />
      <div className="w-full">
        <PasswordChangeForm />
      </div>
    </div>
  );
};

export default UserInfo;