export const styleNeutralizer = `
  /* 모든 인라인 스타일 무시 */
  .ql-editor * {
    font-size: inherit !important;
    font-weight: inherit !important;
    line-height: inherit !important;
    letter-spacing: inherit !important;
    color: inherit !important;
    text-align: inherit !important;
    
    /* 이탤릭과 밑줄 스타일 제거 */
    font-style: normal !important;
    text-decoration: none !important;
  }

  /* em 태그와 u 태그에 대한 추가 오버라이드 */
  .ql-editor em,
  .ql-editor u {
    font-style: normal !important;
    text-decoration: none !important;
  }
`;