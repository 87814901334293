import React from 'react';

const GoogleLogin = ({text}: any) => {
  const GOOGLE_CLIENT_ID = process.env.REACT_APP_GOOGLE_CLIENT_ID;
  const redirectUri = process.env.NODE_ENV === 'production'
    ? 'https://thisisweb.co.kr/auth/google'
    : 'http://localhost:3000/auth/google';
  
  const googleLoginUrl = `https://accounts.google.com/o/oauth2/v2/auth?client_id=${GOOGLE_CLIENT_ID}&response_type=code&redirect_uri=${encodeURIComponent(redirectUri)}&scope=email profile`;

  const handleGoogleLogin = () => {
    window.location.href = googleLoginUrl;
  };

  return (
    <button 
      onClick={handleGoogleLogin}
      className="relative w-full h-[45px] flex items-center bg-white text-gray-700 px-8 rounded border border-gray-300 hover:bg-gray-50"
    >
      <img
        className="ml-1.5 w-[18px] h-[18px]"
        src="/images/auth/google_icon.png"
        alt="google_login"
      />
      <p className="absolute left-[40%]">{text}</p>
    </button>
  );
};

export default GoogleLogin;