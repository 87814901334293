import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useCompleteProfile } from '@/builder/queries/authQueries';
import { useAuthStore } from '@/builder/stores/authStore';
import { ChevronDown, ChevronUp } from 'lucide-react';
import toast from 'react-hot-toast';

interface FormState {
  ceoName: string;
  phoneNumber: string;
  error: string;
  terms: {
    all: boolean;
    service: boolean;
    privacy: boolean;
    marketing: boolean;
  };
}

const CompleteProfile = () => {
  const navigate = useNavigate();
  const { mutate: completeProfile, isPending } = useCompleteProfile();
  const [isTermsOpen, setIsTermsOpen] = useState(false);
  const [formState, setFormState] = useState<FormState>({
    ceoName: '',
    phoneNumber: '',
    error: '',
    terms: {
      all: false,
      service: false,
      privacy: false,
      marketing: false
    }
  });

  const handleAllTerms = (checked: boolean) => {
    setFormState(prev => ({
      ...prev,
      terms: {
        all: checked,
        service: checked,
        privacy: checked,
        marketing: checked
      }
    }));
  };

  const handleTermChange = (term: keyof typeof formState.terms, checked: boolean) => {
    const newTerms = {
      ...formState.terms,
      [term]: checked
    };
    
    newTerms.all = newTerms.service && newTerms.privacy && newTerms.marketing;
    
    setFormState(prev => ({
      ...prev,
      terms: newTerms
    }));
  };

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    setFormState(prev => ({ ...prev, error: '' }));

    if (!formState.ceoName || !formState.phoneNumber) {
      setFormState(prev => ({ ...prev, error: '이름과 전화번호를 입력해주세요.' }));
      return;
    }

    if (!formState.terms.service || !formState.terms.privacy) {
      setFormState(prev => ({ ...prev, error: '필수 약관에 동의해주세요.' }));
      return;
    }

    completeProfile(
      {
        ceoName: formState.ceoName,
        phoneNumber: formState.phoneNumber,
        agreedToService: formState.terms.service,
        agreedToPrivacy: formState.terms.privacy,
        agreedToMarketing: formState.terms.marketing
      },
      {
        onSuccess: (response) => {
          if (response.success) {
            useAuthStore.getState().setUser(response.data.user);
            toast.success('프로필 설정이 완료되었습니다');
            navigate('/admin');
          }
        },
        onError: (error: any) => {
          setFormState(prev => ({
            ...prev,
            error: error.response?.data?.error || '프로필 설정에 실패했습니다'
          }));
        }
      }
    );
  };

  return (
    <section className="px-4 min-h-screen relative flex items-center justify-center">
      <div className="absolute inset-0 overflow-hidden -z-10">
        <img
          src="/images/auth/login.webp"
          alt="complete profile"
          className="object-cover w-full h-full"
        />
      </div>
      
      <div className="p-8 !pb-4 max-w-sm w-full mx-auto bg-white rounded-xl shadow-lg">
        <h2 className="text-2xl font-bold mb-6">추가 정보 입력</h2>
        
        <form onSubmit={handleSubmit} className="space-y-4">
          <div>
            <label className="block text-sm font-medium text-gray-700">
              이름 *
            </label>
            <input
              type="text"
              value={formState.ceoName}
              onChange={(e) => setFormState(prev => ({ ...prev, ceoName: e.target.value }))}
              className="mt-1 block w-full border-gray-300 rounded-md shadow-sm"
              required
            />
          </div>

          <div>
            <label className="block text-sm font-medium text-gray-700">
              연락처 *
            </label>
            <input
              type="number"
              value={formState.phoneNumber}
              onChange={(e) => setFormState(prev => ({ ...prev, phoneNumber: e.target.value }))}
              className="mt-1 block w-full border-gray-300 rounded-md shadow-sm"
              placeholder="01012345678"
              required
            />
          </div>



          <div className="mt-4 border rounded-md overflow-hidden">
            <div className="flex items-center justify-between px-2 py-1 bg-gray-50">
              <div className="flex items-center space-x-2">
                <input
                  type="checkbox"
                  checked={formState.terms.all}
                  onChange={(e) => handleAllTerms(e.target.checked)}
                  className="rounded text-blue-600 focus:ring-blue-500"
                />
                <span className="text-sm font-medium">모든 약관에 동의합니다</span>
              </div>
              <div 
                className="cursor-pointer hover:bg-gray-100 p-2 rounded-full"
                onClick={() => setIsTermsOpen(!isTermsOpen)}
              >
                {isTermsOpen ? <ChevronUp size={20} /> : <ChevronDown size={20} />}
              </div>
            </div>

            {isTermsOpen && (
              <div className="p-4 space-y-3 border-t bg-gray-50">
                <label className="flex items-center justify-between">
                  <div className="flex items-center space-x-2">
                    <input
                      type="checkbox"
                      checked={formState.terms.service}
                      onChange={(e) => handleTermChange('service', e.target.checked)}
                      className="rounded text-blue-600 focus:ring-blue-500"
                    />
                    <span className="text-sm">서비스 이용약관 동의 (필수)</span>
                  </div>
                  <a
                    href="https://husky-coffee-14c.notion.site/149c3ee6db8d8009bd14f4c2a94b5de9"
                    target="_blank"
                    className="text-gray-500 hover:underline text-xs"
                  >
                    [약관보기]
                  </a>
                </label>

                <label className="flex items-center justify-between">
                  <div className="flex items-center space-x-2">
                    <input
                      type="checkbox"
                      checked={formState.terms.privacy}
                      onChange={(e) => handleTermChange('privacy', e.target.checked)}
                      className="rounded text-blue-600 focus:ring-blue-500"
                    />
                    <span className="text-sm">개인정보 처리방침 동의 (필수)</span>
                  </div>
                  <a
                    href="https://husky-coffee-14c.notion.site/149c3ee6db8d8019a5fcdfb5fa1bff1e"
                    target="_blank"
                    className="text-gray-500 hover:underline text-xs"
                  >
                    [약관보기]
                  </a>
                </label>

                <label className="flex items-center space-x-2">
                  <input
                    type="checkbox"
                    checked={formState.terms.marketing}
                    onChange={(e) => handleTermChange('marketing', e.target.checked)}
                    className="rounded text-blue-600 focus:ring-blue-500"
                  />
                  <span className="text-sm">마케팅 정보 수신 동의 (선택)</span>
                </label>
              </div>
            )}
          </div>



          {formState.error && (
            <p className="text-red-500 text-sm">{formState.error}</p>
          )}

          <button
            type="submit"
            disabled={isPending}
            className="w-full bg-blue-500 text-white py-2 px-4 rounded-md hover:bg-blue-600 disabled:bg-blue-300"
          >
            {isPending ? '처리중...' : '완료'}
          </button>
        </form>

        <div className='mt-2 text-right'>
          <a
            href='https://husky-coffee-14c.notion.site/FAQ-14bc3ee6db8d802c8728fc6b13199a2f'
            target='_blank'
            className="font-light text-sm text-gray-500 underline"
          >오류가 발생했어요.</a>
        </div>
      </div>
    </section>
  );
};

export default CompleteProfile;