import React from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import SectionContainer from '@/templates/components/common/SectionContainer';
import ProgressCircle from '@/templates/components/indicators/progress/ProgressCircle';
import stepsData from '@/builder/config/steps.json';
import GoogleLogin from '@/builder/components/auth/GoogleLogin';
import { Mail } from 'lucide-react';



const Signup: React.FC = () => {
  const steps = stepsData.data;
  const navigate = useNavigate();
  const location = useLocation();
  const selectedTemplate = location.state?.selectedTemplate;

  const handleClick = () => {
    navigate('/register', { 
      state: { 
        selectedTemplate
      }
    });
  };

  return (
    <SectionContainer 
      backgroundImage={{
        src: '',
        styles: {
          overlay: {
            color: '#000000',
          }
        }
      }}
      containerClassName="text-white"
    >
      <div className="mb-28">
        <ProgressCircle
          steps={steps}
          currentStep={2}
        />
      </div>

      <div className="max-w-6xl mx-auto">
        <div className='text-center mb-8'>
          <h1 className="text-4xl font-bold">홈페이지 제작 누구나 쉽게</h1>
          <p className='mt-6 text-gray-400'>디스이즈웹에서는 가능합니다</p>
        </div>
        <div className="p-8 !pb-4 max-w-sm w-full mx-auto  rounded-xl shadow-lg">
          <div className='flex flex-col gap-4'>
            <GoogleLogin text='구글로 간편가입' />
            {/* <NaverLogin /> */}
          </div>

          <button
            onClick={handleClick}
            className="relative w-full h-[45px] flex items-center mt-4 bg-gray-100 text-gray-700 px-8 rounded hover:bg-gray-300"
          >
            <Mail className='ml-2 w-[20px] h-[20px]' />
            <p className='absolute left-[40%]'>일반 회원가입</p>
          </button>
        </div>
      </div>
    </SectionContainer>
  );
};

export default Signup;