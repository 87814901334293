import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import NaverLogin from './NaverLogin';
import { Mail } from 'lucide-react';
import GoogleLogin from './GoogleLogin';

export interface LoginFormProps {
  onSubmit: (email: string, password: string) => void;
  isSubmitting?: boolean;
}

export interface LoginFormState {
  email: string;
  password: string;
  error: string;
  showForm: boolean;
}

const LoginForm = ({ onSubmit }: LoginFormProps) => {
  const [formState, setFormState] = useState<LoginFormState>({
    email: '',
    password: '',
    error: '',
    showForm: false
  });

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setFormState(prev => ({ ...prev, error: '' }));

    if (!formState.email || !formState.password) {
      setFormState(prev => ({ ...prev, error: '모든 내용을 입력해 주세요.' }));
      return;
    }

    try {
      await onSubmit(formState.email, formState.password);
    } catch (error) {
      setFormState(prev => ({ 
        ...prev, 
        error: '이메일 또는 비밀번호가 잘못되었습니다.' 
      }));
    }
  };

  const toggleForm = () => {
    setFormState(prev => ({ ...prev, showForm: !prev.showForm }));
  };

  return (
    <div className="p-8 !pb-4 max-w-sm w-full mx-auto bg-white rounded-xl shadow-lg">
      <h2 className="text-2xl font-bold mb-6">THIS IS WEB</h2>
      <div className='flex flex-col gap-4'>
        <GoogleLogin text='구글로 로그인' />
        {/* <NaverLogin /> */}
      </div>

      <button 
        onClick={toggleForm}
        className="relative w-full h-[45px] flex items-center mt-4 bg-gray-200 text-gray-700 px-8 rounded hover:bg-gray-300"
      >
        <Mail className='ml-2 w-[20px] h-[20px]' />
        <p className='absolute left-[40%]'>{formState.showForm ? '이메일로 로그인 닫기' : '일반 로그인'}</p>
      </button>

      {formState.showForm && (
        <form onSubmit={handleSubmit} className="space-y-4 mt-4">
          <div>
            <label htmlFor="email" className="block text-sm font-medium text-gray-700">이메일</label>
            <input
              id="email"
              type="email"
              value={formState.email}
              onChange={(e) => setFormState(prev => ({ ...prev, email: e.target.value }))}
              required
              className="mt-1 block w-full border-gray-300 rounded-md shadow-sm"
            />
          </div>

          <div>
            <label htmlFor="password" className="block text-sm font-medium text-gray-700">비밀번호</label>
            <input
              id="password"
              type="password"
              value={formState.password}
              onChange={(e) => setFormState(prev => ({ ...prev, password: e.target.value }))}
              required
              className="mt-1 block w-full border-gray-300 rounded-md shadow-sm"
            />
          </div>

          {formState.error && <p className="text-red-500">{formState.error}</p>}

          <button
            type="submit"
            className="w-full bg-blue-500 text-white py-2 px-4 rounded-md hover:bg-blue-600"
          >
            로그인
          </button>
        </form>
      )}

      <div className='mt-2 text-right'>
        <a
          href='https://husky-coffee-14c.notion.site/FAQ-14bc3ee6db8d802c8728fc6b13199a2f'
          target='_blank'
          className="font-light text-sm text-gray-500 underline"
        >오류가 발생했어요.</a>
      </div>
    </div>
  );
};

export default LoginForm;