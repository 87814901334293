import { useState } from 'react';
import { Search, Loader, ChevronLeft, ChevronRight } from 'lucide-react';
import toast from 'react-hot-toast';
import searchKeywordMap from './searchKeywordMap';

interface PexelsPhoto {
  id: number;
  photographer: string;
  photographer_url: string;
  src: {
    original: string;
    medium: string;
  };
  alt: string;
}

interface PexelsTabProps {
  onSelect: (url: string) => void;
}

const PexelsTab = ({ onSelect }: PexelsTabProps) => {
  const [searchQuery, setSearchQuery] = useState('');
  const [results, setResults] = useState<PexelsPhoto[]>([]);
  const [isSearching, setIsSearching] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalResults, setTotalResults] = useState(0);
  const [hasSearched, setHasSearched] = useState(false);
  const perPage = 80;

  const searchPexels = async (query: string, page = 1) => {
    if (!query.trim()) return;
    
    setIsSearching(true);
    setHasSearched(true);
    try {
      const englishQuery = searchKeywordMap[query.trim()] || query;
      const response = await fetch(
        `https://api.pexels.com/v1/search?query=${encodeURIComponent(englishQuery)}&page=${page}&per_page=${perPage}`,
        {
          headers: {
            'Authorization': process.env.REACT_APP_PEXELS_API_KEY || ''
          }
        }
      );
      
      if (!response.ok) throw new Error('Failed to fetch images');
      
      const data = await response.json();
      setResults(data.photos);
      setTotalResults(data.total_results);
      setCurrentPage(page);
    } catch (error) {
      toast.error('이미지 검색 중 오류가 발생했습니다.');
    } finally {
      setIsSearching(false);
    }
  };

  const handlePageChange = (newPage: number) => {
    if (newPage >= 1 && newPage <= Math.ceil(totalResults / perPage)) {
      searchPexels(searchQuery, newPage);
    }
  };

  return (
    <div className="space-y-4">
      <div className="relative flex items-center">
        <input
          type="text"
          placeholder="이미지 검색..."
          value={searchQuery}
          onChange={(e) => setSearchQuery(e.target.value)}
          onKeyPress={(e) => {
            if (e.key === 'Enter') {
              e.preventDefault(); // 이벤트 버블링 방지
              searchPexels(searchQuery, 1);
            }
          }}      
          // onKeyPress={(e) => {
          //   if (e.key === 'Enter') searchPexels(searchQuery, 1);
          // }}
          className="w-full pl-4 py-2 border rounded-lg"
        />
        <button
          onClick={(e) => {
            e.preventDefault(); // 버튼 클릭 시에도 이벤트 버블링 방지
            searchPexels(searchQuery, 1);
          }}
          // onClick={() => searchPexels(searchQuery, 1)}
          className="absolute right-2 p-2 text-gray-400 hover:text-gray-600"
        >
          <Search size={18} />
        </button>
      </div>
      <div className="text-sm text-gray-400">
        <p>Photos provided by Pexels</p>
      </div>
      
      {isSearching ? (
        <div className="flex justify-center py-8">
          <Loader className="w-6 h-6 animate-spin text-blue-500" />
        </div>
      ) : (
        <>
          {results.length === 0 && !isSearching && hasSearched && (
            <div className="text-center py-8 text-gray-500">
              해당 한국어 단어 검색결과가 없습니다. 영어로 검색해보세요.
            </div>
          )}
          <div className="grid grid-cols-3 gap-4">
            {results.map((photo) => (
              <div key={photo.id} className="space-y-2">
                <div
                  className="relative group cursor-pointer aspect-video overflow-hidden rounded-lg"
                  onClick={() => onSelect(photo.src.original)}
                >
                  <img
                    src={photo.src.medium}
                    alt={photo.alt}
                    className="w-full h-full object-cover"
                  />
                  <div className="absolute inset-0 bg-black/50 opacity-0 group-hover:opacity-100 transition-opacity" />
                </div>
                <div className="text-xs text-gray-500">
                  Photo by{' '}
                  <a href={photo.photographer_url} target="_blank" rel="noopener noreferrer" className="text-blue-500 hover:underline">
                    {photo.photographer}
                  </a>
                  {' '}on{' '}
                  <a href="https://www.pexels.com" target="_blank" rel="noopener noreferrer" className="text-blue-500 hover:underline">
                    Pexels
                  </a>
                </div>
              </div>
            ))}
          </div>

          {totalResults > 0 && (
            <div className="flex justify-center items-center gap-4 pt-4">
              <button
                onClick={() => handlePageChange(currentPage - 1)}
                disabled={currentPage === 1}
                className="p-2 rounded hover:bg-gray-100 disabled:opacity-50"
              >
                <ChevronLeft size={20} />
              </button>
              <span className="text-sm">
                {currentPage} / {Math.ceil(totalResults / perPage)}
              </span>
              <button
                onClick={() => handlePageChange(currentPage + 1)}
                disabled={currentPage >= Math.ceil(totalResults / perPage)}
                className="p-2 rounded hover:bg-gray-100 disabled:opacity-50"
              >
                <ChevronRight size={20} />
              </button>
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default PexelsTab;

// import { useState } from 'react';
// import { Search, Loader, ChevronLeft, ChevronRight } from 'lucide-react';
// import toast from 'react-hot-toast';
// import searchKeywordMap from './searchKeywordMap';

// interface PexelsPhoto {
//   id: number;
//   photographer: string;
//   photographer_url: string;
//   src: {
//     original: string;
//     medium: string;
//   };
//   alt: string;
// }

// interface PexelsTabProps {
//   onSelect: (url: string) => void;
// }

// const PexelsTab = ({ onSelect }: PexelsTabProps) => {
//   const [searchQuery, setSearchQuery] = useState('');
//   const [results, setResults] = useState<PexelsPhoto[]>([]);
//   const [isSearching, setIsSearching] = useState(false);
//   const [currentPage, setCurrentPage] = useState(1);
//   const [totalResults, setTotalResults] = useState(0);
//   const perPage = 80;


//   const searchPexels = async (query: string, page = 1) => {
//     if (!query.trim()) return;
    
//     setIsSearching(true);
//     try {
//       const englishQuery = searchKeywordMap[query.trim()] || query;
//       const response = await fetch(
//         `https://api.pexels.com/v1/search?query=${encodeURIComponent(englishQuery)}&page=${page}&per_page=${perPage}`,
//         {
//           headers: {
//             'Authorization': process.env.REACT_APP_PEXELS_API_KEY  || ''
//           }
//         }
//       );
      
//       if (!response.ok) throw new Error('Failed to fetch images');
      
//       const data = await response.json();
//       setResults(data.photos);
//       setTotalResults(data.total_results);
//       setCurrentPage(page);
//     } catch (error) {
//       toast.error('이미지 검색 중 오류가 발생했습니다.');
//     } finally {
//       setIsSearching(false);
//     }
//   };

//   const handlePageChange = (newPage: number) => {
//     if (newPage >= 1 && newPage <= Math.ceil(totalResults / perPage)) {
//       searchPexels(searchQuery, newPage);
//     }
//   };

//   return (
//     <div className="space-y-4">
//       <div className="relative">
//         <input
//           type="text"
//           placeholder="이미지 검색..."
//           value={searchQuery}
//           onChange={(e) => setSearchQuery(e.target.value)}
//           onKeyPress={(e) => {
//             if (e.key === 'Enter') searchPexels(searchQuery, 1);
//           }}
//           className="w-full pl-10 pr-4 py-2 border rounded-lg"
//         />
//         <Search 
//           className="absolute left-3 top-1/2 -translate-y-1/2 text-gray-400 cursor-pointer" 
//           size={18}
//           onClick={() => searchPexels(searchQuery, 1)}
//         />
//       </div>
//       <div className=' text-sm text-gray-400'>
//         <p>Photos provided by Pexels</p>
//       </div>
      
//       {isSearching ? (
//         <div className="flex justify-center py-8">
//           <Loader className="w-6 h-6 animate-spin text-blue-500" />
//         </div>
//       ) : (
//         <>
//           {results.length === 0 && !isSearching && searchQuery && (
//             <div className="text-center py-8 text-gray-500">
//               해당 한국어 단어 검색결과가 없습니다. 영어로 검색해보세요.
//             </div>
//           )}
//           <div className="grid grid-cols-3 gap-4">
//             {results.map((photo) => (
//               <div key={photo.id} className="space-y-2">
//                 <div
//                   className="relative group cursor-pointer aspect-video overflow-hidden rounded-lg"
//                   onClick={() => onSelect(photo.src.original)}
//                 >
//                   <img
//                     src={photo.src.medium}
//                     alt={photo.alt}
//                     className="w-full h-full object-cover"
//                   />
//                   <div className="absolute inset-0 bg-black/50 opacity-0 group-hover:opacity-100 transition-opacity" />
//                 </div>
//                 <div className="text-xs text-gray-500">
//                   Photo by{' '}
//                   <a href={photo.photographer_url} target="_blank" rel="noopener noreferrer" className="text-blue-500 hover:underline">
//                     {photo.photographer}
//                   </a>
//                   {' '}on{' '}
//                   <a href="https://www.pexels.com" target="_blank" rel="noopener noreferrer" className="text-blue-500 hover:underline">
//                     Pexels
//                   </a>
//                 </div>
//               </div>
//             ))}
//           </div>

//           {totalResults > 0 && (
//             <div className="flex justify-center items-center gap-4 pt-4">
//               <button
//                 onClick={() => handlePageChange(currentPage - 1)}
//                 disabled={currentPage === 1}
//                 className="p-2 rounded hover:bg-gray-100 disabled:opacity-50"
//               >
//                 <ChevronLeft size={20} />
//               </button>
//               <span className="text-sm">
//                 {currentPage} / {Math.ceil(totalResults / perPage)}
//               </span>
//               <button
//                 onClick={() => handlePageChange(currentPage + 1)}
//                 disabled={currentPage >= Math.ceil(totalResults / perPage)}
//                 className="p-2 rounded hover:bg-gray-100 disabled:opacity-50"
//               >
//                 <ChevronRight size={20} />
//               </button>
//             </div>
//           )}
//         </>
//       )}
//     </div>
//   );
// };

// export default PexelsTab;